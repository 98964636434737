import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transformPath'
})
export class TransformPathPipe implements PipeTransform {
    transform(pathSegments: string[]): string {
        return pathSegments.join(' > ');
    }
}
