import { TransmissionLog, User } from '@app/models';
import { environment as env } from '@env/environment';
import { ISecurityEntryModel, SecurityEntry } from '@app/models/security.models';
import moment, { Moment } from 'moment';


export class Document implements ISecurityEntryModel {
    public id: number;
    public title: string;
    public description: string;
    public keywords: string;
    public author: User;
    public owner_id: number;
    public owner_name: string;
    public versions: DocumentVersion[];
    public archived: boolean;
    public expiry: string;
    public ctime: string;
    public status: string;
    public is_private: boolean;
    public tags: DocumentTag[] = [];
    public transmission: TransmissionLog[] = [];
    public security: SecurityEntry[] = [];
    public readonly modelType: string = 'document';
    public upload_user: string;
    public format_name: string;
    public latest_version: string;
    public viewers: number[];

    public static assign(o: Document): Document {
        const doc: Document = Object.assign(new Document(), o);
        doc.author = doc.author ? User.assign(doc.author) : null;
        doc.is_private = !!doc.is_private;

        if (o.ctime) {
            doc.ctime = moment(o.ctime).format(env.serverDateTimeFormat);
        }

        for (const idx in doc.security) {
            if (idx) {
                doc.security[idx] = Object.assign(new SecurityEntry(), doc.security[idx]);
            }
        }
        for (const idx in doc.transmission) {
            if (idx) {
                doc.transmission[idx] = Object.assign(new TransmissionLog(), doc.transmission[idx]);
                doc.transmission[idx].sender = Object.assign(new User(), doc.transmission[idx].sender);
                doc.transmission[idx].document_version = Object.assign(new DocumentVersion(), doc.transmission[idx].document_version);
            }
        }
        for (const idx in doc.tags) {
            if (idx) {
                doc.tags[idx] = Object.assign(new DocumentTag(), doc.tags[idx]);
            }
        }
        for (const idx in doc.versions) {
            if (idx) {
                doc.versions[idx] = Object.assign(new DocumentVersion(), doc.versions[idx]);
                if (doc.versions[idx].ctime) {
                    doc.versions[idx].ctime = moment(doc.versions[idx].ctime).format(env.serverDateTimeFormat);

                }
                for (const revIdx in doc.versions[idx].reviews) {
                    if (revIdx) {
                        doc.versions[idx].reviews[revIdx] = Object.assign(new DocumentReview(), doc.versions[idx].reviews[revIdx]);
                        doc.versions[idx].reviews[revIdx].ctime = moment(doc.versions[idx].reviews[revIdx].ctime);
                    }
                }
                for (const formatIdx in doc.versions[idx].formats) {
                    if (formatIdx) {
                        doc.versions[idx].formats[formatIdx]
                            = Object.assign(new DocumentFormat(), doc.versions[idx].formats[formatIdx]);
                        for (const fileIdx in doc.versions[idx].formats[formatIdx].files) {
                            if (fileIdx) {
                                doc.versions[idx].formats[formatIdx].files[fileIdx]
                                    = Object.assign(new DocumentFile(), doc.versions[idx]
                                    .formats[formatIdx].files[fileIdx]);
                            }
                        }
                    }
                }
            }
        }
        return doc;
    }

    getLatestVersion(skipFilter = false): DocumentVersion {
        if (this.versions?.length > 0) {
            const sortedVersions = this.versions.filter(v => v.sealed || skipFilter).sort((a, b) => {
                return b.ordinal - a.ordinal;
            });

            if (sortedVersions.length > 0) {
                return sortedVersions[0];
            } else {
                const sortedDraftVersions = this.versions.filter(v => !v.sealed).sort((a, b) => {
                    return b.ordinal - a.ordinal;
                });
                if (sortedDraftVersions.length > 0) {
                    return sortedDraftVersions[0];
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }

    getVersions(): DocumentVersion[] {
        if (this.versions && this.versions.length > 0) {
            return this.versions.sort((a, b) => {
                return b.ordinal - a.ordinal;
            });
        } else {
            return [];
        }
    }

    getKeywords(): string[] {
        if (this.keywords) {
            return this.keywords.trim().split(' ');
        } else {
            return [];
        }
    }

    public getDashboardExpirationTime(): Moment {
        return moment(this.expiry);
    }

    public getExpirationTimeFromVersion(): Moment {
        const version = this.getLatestVersion();
        if (!version || !version.expiry || version.expiry === '') {
            return null;
        }
        return moment(version.expiry);
    }

    public getCreatedTime(): Moment {
        return moment(this.ctime);
    }

    getFormatsWithCacheLock(): DocumentFormat[] {
        const formatsWithCacheLock: DocumentFormat[] = [];
        for (const version of this.versions) {
            formatsWithCacheLock.push(...version.getFormatsWithCacheLock());
        }
        return formatsWithCacheLock;
    }

}

export class DocumentVersion {
    public id: number;
    public document_id: number;
    public primary_format_id: number;
    public ordinal: number;
    public version: string;
    public sealed: boolean;
    public is_draft: boolean;
    public ctime: string;
    public mtime: string;
    public created_user_id: number;
    public created_user_name: number;
    public modified_user_id: number;
    public modified_user_name: number;
    public expiry: string;
    public reviews: DocumentReview[];
    public formats: DocumentFormat[];

    public getCreatedTime(): Moment {
        return moment(this.ctime);
    }

    public getModifiedTime(): Moment {
        return this.mtime ? moment(this.mtime) : this.getCreatedTime();
    }

    public getExpirationTime(): Moment {
        return moment(this.expiry);
    }

    public isViewable(): boolean {
        return this.formats.some(f => !!f.getPages().length);
    }

    /**
     * Returns an array of DocumentFilePage objects, which can be used for rendering previews
     * @param onlyPrimaryFormat If true, the function will only return pages for the primary format specified in `primary_format_id`
     * @returns {DocumentFilePage[]}
     */
    public getPages(onlyPrimaryFormat: boolean = false): DocumentFilePage[] {

        const pages: DocumentFilePage[] = [];

        if (onlyPrimaryFormat && !this.getPrimaryFormat()) {
            return pages;
        }

        for (const format of onlyPrimaryFormat ? [this.getPrimaryFormat()] : this.formats) {
            let formatOrdinal = 1;
            let fileIdx = 0;

            if (!format) {
                return pages;
            }

            if (format.files) {
                for (const file of format.files) {
                    for (let filePageIdx = 0; filePageIdx < file.page_count; filePageIdx++) {
                        const newPage = new DocumentFilePage();
                        newPage.file_index = fileIdx;
                        newPage.file_ordinal = filePageIdx;
                        newPage.format_ordinal = formatOrdinal++;
                        newPage.file = file;
                        pages.push(newPage);
                    }
                    fileIdx++;
                }
            }
        }

        return pages;

    }

    getPagesForFormat(format: DocumentFormat) {
        const pages: DocumentFilePage[] = [];
        let formatOrdinal = 1;
        let fileIdx = 0;

        if (!format) {
            return pages;
        }

        for (const file of format.files) {
            for (let filePageIdx = 0; filePageIdx < file.page_count; filePageIdx++) {
                const newPage = new DocumentFilePage();
                newPage.file_index = fileIdx;
                newPage.file_ordinal = filePageIdx;
                newPage.format_ordinal = formatOrdinal++;
                newPage.file = file;
                pages.push(newPage);
            }
            fileIdx++;
        }
        return pages;
    }

    getPrimaryFormat(): DocumentFormat {
        if (this.primary_format_id) {
            const formats = this.formats.filter(f => f.id === this.primary_format_id);

            if (formats.length > 0) {
                return formats[0];
            } else {
                throw new Error('Invalid value in primary_format_id for DocumentVersion(#' + this.id + ')');
            }

        } else {
            if (this.formats.length > 0) {
                // No primary format, return first PDF format, or any viewable, or just the first
                let validFormat: DocumentFormat;
                validFormat = this.formats.find(f => f.name.toLowerCase() === 'pdf' && f.getPages().length);
                if (!validFormat) {
                    validFormat = this.formats.find(f => f.getPages().length);
                }
                return validFormat ? validFormat : this.formats[0];
            } else {
                return null;
            }
        }
    }

    isPublishable() {
        const hasPages = this.formats.find(f => f.id === this.primary_format_id)?.files.some(f => f.page_count > 0);
        return this.is_draft && !this.sealed && hasPages && this.primary_format_id !== null;
    }

    isUnpublishable() {
        return this.sealed;
    }

    getFormatsWithCacheLock() {
        const formatsWithLock: DocumentFormat[] = [];
        for (const format of this.formats) {
            if (format.hasCacheLock()) {
                formatsWithLock.push(format);
            }
        }
        return formatsWithLock;
    }

}

export class UploadFormat {
    public name: string = '';
    public files: File[] = [];
    public default: boolean = false;
}

export class DocumentFormat {
    public id: number;
    public document_version_id: number;
    public name: string;
    public is_cache: boolean;
    public ordinal: number;
    public cache_status: string;
    public files: DocumentFile[] = [];
    public _isInNameEditMode = false;
    public showNameError = false;

    public static assign(o: DocumentFormat): DocumentFormat {
        const n = Object.assign(new DocumentFormat(), o);
        for (const idx in n.files) {
            if (idx) {
                n.files[idx] = DocumentFile.assign(n.files[idx]);
            }
        }
        return n;
    }

    public hasCacheLock(): boolean {
        return this.cache_status !== '';
    }

    /**
     * Returns an array of DocumentFilePage objects, which can be used for rendering previews
     * @returns {DocumentFilePage[]}
     */
    public getPages(): DocumentFilePage[] {

        const pages: DocumentFilePage[] = [];

        let formatOrdinal = 1;
        let fileIdx = 0;

        for (const file of this.files) {
            for (let filePageIdx = 0; filePageIdx < file.page_count; filePageIdx++) {
                const newPage = new DocumentFilePage();
                newPage.file_index = fileIdx;
                newPage.file_ordinal = filePageIdx;
                newPage.format_ordinal = formatOrdinal++;
                newPage.file = file;
                pages.push(newPage);
            }
            fileIdx++;
        }
        return pages;
    }
}

export class DocumentFile {
    public id: number;
    public document_format_id: number;
    public ordinal: number;
    public page_count: number;
    public filename: string;
    public url: string;
    public access_key: string;
    public upload_time: Moment;
    public upload_user_id: number;
    public upload_user_name: string;

    public static assign(o: DocumentFile): DocumentFile {
        return Object.assign(new DocumentFile(), o);
    }
}

export class DocumentFilePage {
    public file: DocumentFile;
    public file_index: number;
    public format_ordinal: number;
    public file_ordinal: number;

}

export enum DocumentReviewType {
    COMMENT = 'COMMENT',
    REVIEWED = 'REVIEWED',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED'
}

export class DocumentReview {
    public id: number;
    public ctime: Moment;
    public user_id: number;
    public user_name: number;
    public document_version_id: number;
    public comment: string;
    public state: DocumentReviewType = DocumentReviewType.COMMENT;

    public version: DocumentVersion;
}

export class DocumentTag {
    id: number;
    document_id: number;
    tag_id: number;
    value: string;
    required: boolean;
    description: string;
}
