import { TagValue } from '@app/models';

export class Tag {
    [key: string]: any; // Make the model string indexable, eg  w['surname']

    constructor(public id: number = null,
                public parent_tag_id: number = null,
                public name: string = '',
                public type: string = null,
                public required: boolean = null,
                public allow_multi: boolean = null,
                public search: boolean = null,
                public config: string = null,
                public description: string = null,
                public values: TagValue[] = []
    ) {
    }

    public static assign(o: Tag) {
        return Object.assign(new Tag(), o);
    }
}
