import { DocumentVersion } from '@app/models';
import moment, { Moment } from 'moment';

export class ReviewRequest {
    [key: string]: any;

    constructor(public id: number = null,
                public document_version_id: number = null,
                public requestor_id: number = null,
                public reviewer_id: number = null,
                public review_type: string = null,
                public request_time: Date = null,
                public closed_time: Date = null,
                public requestor_comment: string = null,
                public requestor_name: string = null,
                public reviewer_name: string = null,
                public document_title: string = null,
                public document_version_title: string = null,
                public document_id: number = null,
                public version_status: string = null,
                public document_version: DocumentVersion = null
    ) {
    }

    public static assign(o: ReviewRequest) {
        const n = Object.assign(new ReviewRequest(), o);
        if (n.request_time) {
            n.request_time = new Date(n.request_time);
        }
        if (n.closed_time) {
            n.closed_time = new Date(n.closed_time);
        }
        return n;
    }

    public getCreatedTime(): Moment {
        return moment(this.request_time);
    }

    public getClosedTime(): Moment {
        return moment(this.closed_time);
    }
}
