import moment, { Moment } from 'moment';

export class Notification {
    [key: string]: any;

    constructor(public id: number = null,
                public title: string = null,
                public content: string = null,
                public ctime: Date = null,
                public target_path: string = null,
                public user_id: number = null,
                public is_read: boolean = null
    ) {
    }

    public static assign(o: Notification) {
        const n = Object.assign(new Notification(), o);
        if (n.ctime) {
            n.ctime = new Date(n.ctime);
        }
        return n;
    }


    public getCreatedTime(): Moment {
        return moment(this.ctime);
    }
}


export interface NotificationPing {
    user_id: number;
    notification_date: Date;
}
