import { map, mergeMap, retry, zipWith } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { pipe, range, timer } from 'rxjs';

/**
 * This function retries to run the underlying HTTP request upon failure for a given number of tries with a given interval
 * @returns {UnaryFunction<any, any>}
 */
export function keepTrying<T>() {
    return env.httpSettings.retryEnabled ? pipe<any, any>(
        retry({
            delay: (attempts: any) => range(1, env.httpSettings.maxNumberTriesBeforeFail)
                .pipe(
                    zipWith(attempts, [(i) => i]),
                    map(i => Number(i) * Number(i)),
                    mergeMap(i => timer(i * env.httpSettings.retryInterval))
                )
        })
    ) : pipe<any, any>(e => e);
}

